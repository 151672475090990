@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

.wrapper {
  background: $white-0;
  border: 1px solid $neutral-3;
  border-radius: 8px;
  padding: 14px 26px 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: $black-0;
    margin: 0;
  }
  &.isInactive {
    cursor: default;
    .actionDropdown {
      margin-right: 0;
      margin-left: 0;
    }
  }
}
.actionDropdown {
  margin-left: auto;
  margin-right: 12px;
}
.inactiveTag {
  margin-left: auto;
  margin-right: 16px;
  background: #fbe0c5;
  border-radius: 8px;
  color: #ec7600;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 10px;
}

// Responsive
@include responsive(md1_nl) {
  .wrapper {
    h3 {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
