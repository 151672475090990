.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1px;
  color: #0e121a;
}
.top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.info {
  display: flex;
  gap: 5px;
  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    &:nth-child(2) {
      font-weight: normal;
      color: #b0b4b9;
    }
  }
}
.bottom {
  padding-left: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
