@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';
@import 'src/assets/scss/mixins';

.inputWrapper {
  &.error {
    :global {
      .mantine-Input-input {
        border-color: $danger-1;
      }
    }
  }
  &.errorBg {
    :global {
      .mantine-Input-input {
        color: $danger-1 !important;
        background: $danger-0 !important;
        -webkit-box-shadow: 0 0 0 30px $danger-0 inset !important;
        &::placeholder {
          color: rgba($danger-1, 0.5) !important;
        }
      }
    }
  }
  &.hasIcon {
    position: relative;
    :global {
      .mantine-Input-input {
        padding-right: 40px;
      }
    }
  }
  &.errorOverlay {
    :global {
      .mantine-Input-wrapper {
        margin-bottom: 0;
      }
      .mantine-InputWrapper-error {
        position: absolute;
        margin-top: 1px;
      }
    }
  }
  &.disabledOverlay {
    :global {
      .mantine-Input-disabled:disabled {
        @include disabledInputOverlay();
      }
    }
  }
  &.isSearch {
    .icon {
      svg {
        circle {
          stroke: #838383;
        }
        path {
          stroke: #838383;
        }
      }
    }
  }
  &.layout_horizontal {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
  align-items: center;
  display: flex;
}
.wrapper {
  &.text_md {
    :global {
      .mantine-Input-input {
        font-size: 14px;
      }
    }
  }
}

// Responsive
@include responsive(xs) {
  .icon {
    > svg {
      width: 18px;
      height: 18px;
    }
  }
  :global {
    .mantine-Input-input {
      font-size: 12px;
    }
  }
}
