.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  button {
    min-height: 32px;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }
}
.modalWrapper {
  :global {
    .mantine-Paper-root.mantine-Modal-modal {
      padding: 16px 20px;
    }
    .mantine-Modal-header {
      margin-bottom: 8px;
      padding-bottom: 0;
      .mantine-Modal-title {
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: black;
      }
    }
  }
}
