@import 'src/assets/scss/colors';
@import 'src/assets/scss/mixins';
@import 'src/assets/scss/responsive';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.header {
  display: flex;
  gap: 12px;
  > * {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $black-2;
    &:last-child {
      text-align: right;
      padding-right: 16px;
    }
    &:first-child {
      text-align: left;
      padding: 0;
    }
    &:nth-child(2) {
      padding-left: 6px;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  > * {
    flex: 1;
    width: 100%;
  }
}
.contentCard {
  background: $white-0;
  border: 1px solid $neutral-3;
  border-radius: 8px;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  > div {
    display: flex;
    &:last-child {
      justify-content: flex-end;
    }
  }
  &.profile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    > * {
      width: 100%;
      display: flex;
      gap: 14px;
      align-items: center;
    }
    :global {
      .mantine-InputWrapper-root {
        width: 100%;
      }
      .mantine-Select-root {
        display: flex;
        align-items: center;
        gap: 14px;
        > label {
          & + div {
            flex: 1;
          }
        }
      }
    }
  }
  &.calendar {
    overflow-x: auto;
    > ul {
      padding: 0;
      margin: 0;
      list-style: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      > li {
        display: flex;
        align-items: center;
        gap: 6px;
        > div {
          &:nth-child(1) {
            min-width: 100px;
            max-width: 100px;
          }
          &:nth-child(2) {
            flex: 1;
          }
          &:nth-child(3) {
            flex: 1;
          }
        }
        > span {
          display: flex;
          &:last-child {
            min-width: 20px;
          }
        }
        &:not(:nth-child(1)) {
          > div {
            &:nth-child(1) {
              font-weight: 600;
            }
          }
        }
        &:nth-child(1) {
          > div {
            &:not(:nth-child(1)) {
              padding-left: 8px;
            }
          }
        }
        &.calendarCardItem {
          > span {
            cursor: pointer;
          }
          &:not(.itemActive) {
            > span {
              svg {
                transform: rotate(-45deg);
                path {
                  stroke: $black-2;
                }
              }
            }
          }
        }
        :global {
          .mantine-Select-root {
            min-width: 90px;
          }
        }
      }
    }
  }
}
.profileColumn {
  display: flex;
  align-items: center;
  gap: 14px;
  :global {
    div {
      &:not(.mantine-Avatar-root) {
        display: flex;
        flex-direction: column;
        > span {
          color: $black-2;
          &:first-child {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 5px;
            flex-wrap: wrap;
            > span {
              display: flex;
              gap: 5px;
            }
          }
          &:nth-child(2) {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }
}
.calendarView {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    li {
      display: flex;
      align-items: center;
      gap: 8px;
      > span {
        &:nth-child(1) {
          width: 88px;
          font-weight: 400;
        }
      }
    }
  }
}
.mobileLabel {
  display: none;
}
.loading {
  top: 37px;
}

// Responsive
@include responsive(sm_nl) {
  .header {
    > * {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: none;
      &:first-child {
        display: inline-block;
        width: 100% !important;
      }
    }
  }
  .contentCard {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    position: relative;
    > div {
      min-width: 100%;
      max-width: 100%;
      padding-bottom: 12px;
      font-size: 12px;
      &:last-child {
        justify-content: flex-start;
        padding-bottom: 0;
      }
      &.cardAction {
        min-width: 1px;
        width: 24px;
      }
    }
    &.calendar {
      > ul {
        > li {
          > div {
            &:first-child {
              min-width: 80px;
              max-width: 80px;
            }
          }
          &:not(:nth-child(1)) {
            > div {
              font-size: 12px;
            }
          }
        }
      }
    }
    &.profile {
      :global {
        .mantine-Textarea-root {
          display: flex;
          align-items: center;
          gap: 14px;
          .mantine-Textarea-wrapper {
            flex: 1;
          }
        }
      }
    }
    &.hasAction {
      padding-right: 52px;
    }
  }
  .profileColumn {
    padding-top: 8px;
    &.mobileBackground {
      position: relative;
      * {
        z-index: 2;
      }
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: -12px;
        right: -12px;
        top: -12px;
        bottom: 0;
        background: #f8f9fa;
        border-radius: 8px 8px 0px 0px;
        z-index: 1;
      }
    }
  }
  .mobileLabel {
    display: flex;
    > span {
      &:first-child {
        font-weight: 500;
        min-width: 85px;
      }
      &:nth-child(2) {
        margin: 0 24px;
      }
    }
  }
  .cardAction {
    position: absolute;
    right: 16px;
    bottom: 24px;
  }
}
