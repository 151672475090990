.modalWrapper {
  :global {
    .mantine-Modal-header {
      border: 0;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > * {
    min-width: 100%;
  }
  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    > li {
      display: flex;
      border-radius: 8px;
      padding: 4px 10px;
      color: #0e121a;
      font-size: 18px;
      > span {
        cursor: pointer;
      }
      &:hover,
      &.active {
        background: #daedf2;
        color: #5f98a9;
        font-weight: 500;
      }
    }
  }
}
