@import 'src/assets/scss/colors';

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.content {
  border: 1px solid $neutral-3;
  border-radius: 8px;
  padding: 12px 12px 12px 24px;
  flex: 1;
}
.title {
  display: flex;
  align-items: center;
  gap: 8px;
  .arrow {
    margin-left: auto;
  }
}
.handler {
  display: flex;
  width: 24px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  > button {
    &:nth-child(1) {
      margin-right: auto;
    }
  }
}
.deleteBtn {
  border-color: $danger-1 !important;
}
.sectionForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $gray-2;
    margin: 0;
  }
}
.formLine {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  > * {
    flex: 1;
  }
  &.items2 {
    > * {
      max-width: calc(50% - 8px);
      min-width: calc(50% - 8px);
    }
  }
  &.items3 {
    > * {
      &:nth-child(1) {
        max-width: calc(50% - 8px);
        min-width: calc(50% - 8px);
      }
    }
  }
}
.associatedFile {
  min-width: 220px;
  max-width: 220px;
}
.assignStatusField {
  max-width: 310px;
}
.additionalChecks {
  display: flex;
  gap: 16px;
  align-items: center;
  > span {
    display: inline-flex;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $primary-0;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
    color: $primary-0;
    align-items: center;
    > svg {
      transform: rotate(-45deg);
      width: 20px;
      height: 20px;
      cursor: pointer;
      path {
        stroke: $gray-2;
      }
    }
  }
}
