@import 'src/assets/scss/colors';

.scheduledInterview {
  display: flex;
  gap: 6px;
  margin-right: 16px;
  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e121a;
    &:nth-child(1) {
      color: $gray-10;
    }
  }
}
.noticeOptions {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
.noticeOptionHeaderAdditional {
  display: flex;
  align-items: center;
  gap: 16px;
  > span {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: $gray-11;
  }
}
.addNotice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.addInterview {
  display: flex;
  align-items: center;
  gap: 12px;
  :global {
    .mantine-Input-wrapper {
      width: 105px;
    }
  }
}
