@import 'src/assets/scss/colors';
@import 'src/assets/scss/mixins';

.wrapper {
  > button {
    width: 24px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    :global {
      .mantine-Button-label {
        > span {
          display: flex;
        }
        svg {
          path {
            fill: $neutral-3;
          }
        }
      }
    }
    &:hover {
      background: $primary-1;
      :global {
        .mantine-Button-label {
          svg {
            path {
              fill: $black-6;
            }
          }
        }
      }
    }
  }
}
