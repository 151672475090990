@import 'src/assets/scss/colors';
@import 'src/assets/scss/mixins';
@import 'src/assets/scss/responsive.scss';

.wrapper {
  font-family: 'Montserrat';
  &.error {
    .heading {
      margin-bottom: 21px;
    }
  }
}
.heading {
  margin-bottom: 36px;
  > h1,
  p {
    text-align: center;
  }
  > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    color: $gray-2;
    margin: 0;
  }
}
.fields {
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}
.actions {
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  > * {
    margin-top: 16px;
  }
}
.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 100%;
  }
  > span {
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: center;
  }
}
.errorMsg {
  @include errorMessage();
}
.alert {
  margin-bottom: 16px;
}
.partners {
  margin-top: 42px;
  display: flex;
  align-items: center;
  gap: 19px;
  a {
    border: 1px solid $gray-0;
    border-radius: 5px;
    padding: 0 6px;
    img {
      max-width: 100%;
    }
  }
}

// Responsive
@include responsive(xs) {
  .wrapper {
    &.error {
      .heading {
        margin-bottom: 8px;
      }
    }
  }
  .heading {
    margin-bottom: 24px;
    > h1 {
      margin-bottom: 8px;
    }
    > p {
      font-size: 14px;
    }
  }
  .fields {
    > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .actions {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 16px;
    > * {
      margin-top: 20px;
    }
  }
  .partners {
    margin-top: 26px;
  }
}
