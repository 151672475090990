@import 'src/assets/scss/colors';

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}
.info {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black-0;
}
.downloadBtn {
  margin-right: auto;
}
.wrapper {
  padding-bottom: 0;
}
.uploadCheckboxes {
  display: flex;
  flex-direction: column;
  gap: 12px;
  > div {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  > span {
    font-size: 16px;
    font-weight: 700;
    color: $black-0;
  }
}

// Print
@media print {
  .wrapper {
    display: none !important;
  }
}
