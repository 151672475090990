@import 'src/assets/scss/colors';
@import 'src/assets/scss/constants';

.wrapper {
  background: $white-0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: $black-1;
    margin: 0;
    padding: 0 3px 12px 3px;
    border-bottom: 1px solid $neutral-3;
  }
}
.folders {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0 0 0;
  flex: 1;
}
.rootFolderDetailsDrawer {
  :global {
    .mantine-Drawer-drawer {
      left: calc(#{$mainSidebarWidth} + #{$documentSidebarW});
    }
  }
  &.isCollapse {
    :global {
      .mantine-Drawer-drawer {
        left: calc(#{$mainSidebarCollapseWidth} + #{$documentSidebarW});
      }
    }
  }
}
