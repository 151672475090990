@import 'src/assets/scss/colors';

.wrapper {
  &.pointer {
    input {
      cursor: pointer;
    }
  }
  &.color_primary {
    :global {
      .mantine-Checkbox-input {
        border-width: 2px;
        border-color: $primary-0;
      }
      .mantine-Checkbox-inner {
        width: 22px;
        height: 22px;
      }
      .mantine-Checkbox-icon {
        top: -1px;
        left: -1px;
      }
    }
  }
}
