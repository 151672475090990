@import 'src/assets/scss/responsive';
@import 'src/assets/scss/mixins';

.wrapper {
  padding: 0;
  margin: 0;
  list-style: none;
  > li {
    display: flex;
    gap: 12px;
    border-top: 1px solid #e0e0e0;
    position: relative;
    padding: 12px 50px 12px 14px;
    cursor: pointer;
    .arrowRight {
      position: absolute;
      right: 20px;
      display: none;
    }
    &:hover {
      background: #eff6f8;
      .arrowRight {
        display: block;
      }
      .taskText {
        padding-left: 12px;
      }
    }
  }
}
.circle {
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  display: inline-block;
  &:not(.hidden) {
    background-color: #5f98a9;
    border-radius: 50%;
    position: relative;
    top: 5px;
  }
}
.taskText {
  font-size: 16px;
  line-height: 24px;
  color: #0e1219;
  @include ellipsisLongText();
}
// Responsive
@include responsive(sm_nl) {
  .wrapper {
    > li {
      padding-left: 6px;
    }
  }
  .taskText {
    font-size: 14px;
    line-height: 150%;
  }
  .circle {
    &:not(.hidden) {
      top: 4px;
    }
  }
}
