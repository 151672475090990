@import 'src/assets/scss/responsive';
@import 'src/assets/scss/colors';

.form {
  .mainContent {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0 0 0;
    margin: 0 auto;
    :global {
      .mantine-Input-rightSection {
        color: $gray-1;
        font-size: 16px;
      }
      .mantine-Checkbox-label {
        font-weight: 500;
        color: $black-1;
      }
    }
  }
}
.mainContent {
  .group {
    > div {
      margin: 16px 0px;
      &:first-child {
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  :global {
    .mantine-Textarea-root {
      .mantine-Textarea-wrapper {
        textarea {
          min-height: 130px;
        }
      }
    }
  }
}

.footer {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

// Responsive
@include responsive(md1_nl) {
}

// Print
@media print {
  .mainContentWrapper {
    overflow: hidden !important;
    button {
      display: none !important;
    }
  }
  :global {
    .mantine-Card-root {
      background: transparent !important;
      border: 0 !important;
    }
    .i-card-required {
      display: none !important;
    }
  }
  .mainContent {
    .group {
      > div {
        margin: 0;
      }
    }
  }
}
