@import 'src/assets/scss/colors';

.wrapper {
  :global {
    .i-card-main-content {
      flex-direction: row;
      align-items: center;
      gap: 28px;
      padding-top: 0 !important;
      margin-top: -8px;
    }
  }
}
.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  > span {
    display: flex;
    gap: 18px;
    > span {
      &:first-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $gray-6;
        min-width: 50px;
        max-width: 50px;
        display: inline-block;
      }
      &:last-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $black-3;
      }
    }
  }
}
