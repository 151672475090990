@import 'src/assets/scss/responsive';

.pageContent {
  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &:nth-child(1) {
      width: 60%;
      gap: 10px;
    }
    &:nth-child(2) {
      width: 40%;
    }
  }
}
.mainContent {
  position: relative;
  .pageHeader {
    border-bottom: 0;
    padding-bottom: 8px;
    .searchField {
      width: 260px;
    }
    .filterDropdown {
      min-width: 170px;
      // width: 170px;
    }
    .departmentDropdown {
      min-width: 230px;
      // width: 230px;
    }
    > div {
      > h1 {
        margin-right: auto;
        & + div {
          flex-wrap: wrap;
          margin-left: 0;
        }
      }
    }
  }
}
.mainTab {
  color: red;
  :global {
    .mantine-Tabs-tabsList
      > .mantine-UnstyledButton-root
      > .mantine-Tabs-tabLabel {
      color: #8e9195;
    }
  }
}

.mainTab {
  :global {
    .mantine-Tabs-tabsList
      > .mantine-UnstyledButton-root
      > .mantine-Tabs-tabLabel {
      color: #8e9195;
    }
  }
}

// Responsive
@include responsive(md1_nl) {
  .pageContent {
    flex-wrap: wrap;
    > div {
      width: 100%;
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
  .pageHeader {
    .searchField {
      width: 100%;
    }
    :global {
      .mantine-InputWrapper-root {
        min-width: calc(40% - 12px);
        max-width: calc(40% - 12px);
        &.mantine-Select-root {
          min-width: calc(60% - 12px);
          max-width: calc(60% - 12px);
        }
      }
    }
    .searchFieldWrapper {
      min-width: 1px;
      width: 100%;
      max-width: 360px;
    }
  }
}
@include responsive(sm_nl) {
  .pageContent {
    .userListHeader {
      display: none;
    }
  }
  .mainContent {
    :global {
      .mantine-Tabs-root {
        margin-top: 24px;
        .mantine-Tabs-tabsList {
          > .mantine-UnstyledButton-root {
            width: 50%;
            &:nth-child(1) {
              padding-right: 50px;
            }
            &:nth-child(2) {
              padding-right: 0;
            }
          }
        }
        .mantine-Tabs-panel {
          display: none !important;
        }
      }
    }
  }
}
