@import 'src/assets/scss/colors';

.responseDateInputWrapper {
  margin-right: 8px;
}
.responseDateInput {
  > input {
    min-height: 30px;
    height: 30px;
  }
}
.wrapper {
  > div {
    &:nth-child(1) {
      > div {
        &:nth-child(1) {
          flex: none;
        }
      }
    }
  }
  &.isSub {
    max-width: calc(100% - 16px);
    min-width: calc(100% - 16px);
    margin-left: 16px;
  }
}
.headerAdditional {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 16px;
  // margin-right: 16px;
  gap: 16px;
  > * {
    &:nth-child(1) {
      margin-right: auto;
    }
  }
}
.bellIcon {
  margin-right: 49px;
  cursor: pointer;
  &.bellParentHasSub {
    margin-right: 24px;
  }
  path {
    stroke: $primary-4;
  }
}
.toggleContent {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 8px;
    > li {
      width: 100%;
      display: flex;
      border: 1px solid #ced4da;
      border-radius: 8px;
      padding: 8px 16px;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > span {
        line-height: 27px;
        font-size: 18px;
        font-weight: 500;
        color: #0e121a;
      }
    }
  }
  :global {
    .mantine-Input-input {
      width: 180px;
    }
  }
}
.dropdownSelect {
  :global {
    .mantine-Select-input {
      height: 30px !important;
      width: 180px !important;
      font-size: 14px !important;
    }
  }
}
