@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';
@import 'src/assets/scss/constants';

:global {
  .i-top-header {
    min-height: 70px;
    max-height: 70px;
    background: $white-0;
  }
  .i-main-content {
    width: 100%;
    flex: 1;
    padding: 24px;
  }
}

.wrapper {
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  &.collapse {
    .left {
      min-width: $mainSidebarCollapseWidth;
      max-width: $mainSidebarCollapseWidth;
    }
    .right {
      margin-left: $mainSidebarCollapseWidth;
      max-width: calc(100% - #{$mainSidebarCollapseWidth});
    }
    .logoBox {
      justify-content: center;
      .logo {
        display: none;
      }
      &:before {
        left: 2px;
        right: 2px;
      }
    }
    .nav {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.left {
  min-width: $mainSidebarWidth;
  max-width: $mainSidebarWidth;
  background: $white-0;
  display: flex;
  flex-direction: column;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.right {
  flex: 1;
  margin-left: $mainSidebarWidth;
  max-width: calc(100% - #{$mainSidebarWidth});
  background: $neutral-1;
  display: flex;
  flex-direction: column;
}
.logoBox {
  padding: 21px 14px 21px 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  &:before {
    content: ' ';
    display: block;
    width: auto;
    height: 1px;
    border-top: 1px solid $white-5;
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
  }
}
.nav {
  padding: 16px 16px 16px 14px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
  &.hideInfo {
    > div {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  > div {
    &:last-child {
      margin-top: auto;
    }
  }
}
.information {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $gray-6;
}
.middleNav {
  margin-top: 48px;
  margin-bottom: 48px;
}

// Responsive
@include responsive(md1_nl) {
  :global {
    .i-main-content {
      padding: 16px;
    }
  }
  .nav {
    :global {
      .i-icon {
        > svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
@include responsive(sm_nl) {
  .right {
    margin-left: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

// Print
@media print {
  .left {
    display: none !important;
  }
  .right {
    max-width: 100% !important;
    margin-left: 0 !important;
    background: transparent !important;
  }
}
