@import 'src/assets/scss/colors';

.wrapper {
  width: 100%;
}
.form {
  .mainContent {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0 0 0;
    margin: 0 auto;
  }
}
.mainContent {
  .group {
    > div {
      margin: 16px 0px;
      &:first-child {
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  :global {
    .mantine-Textarea-root {
      .mantine-Textarea-wrapper {
        textarea {
          min-height: 130px;
        }
      }
    }
  }
}

.footer {
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
  }
}
