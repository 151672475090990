@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';
@import 'src/assets/scss/mixins';

.wrapper {
  &.titleNotBorder {
    .mainContent {
      padding-top: 4px;
    }
  }
  &.notPaddingContent {
    .mainContent {
      padding: 0;
    }
  }
  &.notMarginContent {
    .mainContent {
      margin: 0;
    }
  }
  &.notPaddingContentTop {
    .mainContent {
      padding-top: 0;
    }
  }
  &.gapSizemd {
    .mainContent {
      gap: 20px;
    }
  }
  &.gapSizelg {
    .mainContent {
      gap: 24px;
    }
  }
  &.gapSizexl {
    .mainContent {
      gap: 32px;
    }
  }
  &.contentRow {
    .mainContent {
      flex-direction: row;
      > div {
        flex: 1;
      }
    }
  }
  &.mainContentHasForm {
    .mainContent {
      padding-bottom: 28px;
    }
  }
  &.paddingBottom {
    padding-bottom: 40px;
    .mainContent {
      margin-bottom: 0;
    }
  }
  &.form {
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    height: 100%;
    > div {
      height: 100%;
    }
    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: $black-1;
      margin: 0;
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
  &.overflowInit {
    overflow: initial;
  }
  &.error {
    border-color: $danger-1;
  }
  &.isComingSoon {
    position: relative;
    &:before {
      content: 'Coming Soon';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(37, 37, 37, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #0e121a;
    }
    > * {
      filter: blur(4px);
      -webkit-filter: blur(4px);
    }
  }
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: $black-1;
  }
}
.titleErrorText {
  font-size: 14px;
  color: $danger-1;
  font-weight: normal;
  display: inline-block;
  margin-left: 5px;
}
.mainContent {
  display: flex;
  flex-direction: column;
}
.formCardContent {
  display: grid;
  gap: 16px;
  align-items: center;
  width: 100%;
  min-width: 100%;
  :global {
    .mantine-Switch-root {
      display: flex;
      height: 100%;
      align-items: flex-end;
      padding-bottom: 10px;
    }
  }
  &.displayClose {
    width: calc(100% - 36px);
    min-width: calc(100% - 36px);
    max-width: calc(100% - 36px);
  }
}
.required {
  @include requiredMark();
  position: relative;
  top: -5px;
  height: 10px;
}
.formCardArrayBlock {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  .removeIcon {
    position: relative;
    bottom: 8px;
    .removeIconText {
      display: none;
      color: $neutral-3;
    }
  }
}
.hiddenIcon {
  min-width: 20px;
}

// Responsive
@include responsive(md1_nl) {
  .formCardArrayBlock {
    flex-direction: column;
    .removeIcon {
      align-self: flex-start;
      bottom: 0;
      padding: 4px 8px;
      border: 1px solid $neutral-3;
      border-radius: 8px;
      gap: 8px;
      .removeIconText {
        display: inline-block;
      }
    }
  }
  .formCardContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > * {
      min-width: calc(50% - 8px);
      max-width: calc(50% - 8px);
    }
    :global {
      .mantine-Switch-root {
        margin-top: auto;
      }
    }
  }
}

@include responsive(xs) {
  .titleWrapper {
    .title {
      font-size: 16px;
      margin-right: 20px;
    }
  }
  .formCardContent {
    > * {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
@media print {
  .formCardContent {
    align-items: flex-start;
  }
  .removeIcon {
    display: none !important;
  }
}
