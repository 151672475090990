.wrapper {
  &.fixed {
    position: fixed;
    :global {
      .mantine-Overlay-root {
        position: fixed;
      }
    }
  }
}
