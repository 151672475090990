// Print
@media print {
  .wrapper {
    :global {
      .mantine-Card-cardSection {
        gap: 0 !important;
      }
    }
  }
  .uploadFiles {
    margin-top: 24px;
  }
}
