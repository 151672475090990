@import 'src/assets/scss/responsive';

.mainContent {
  .pageContent {
    &:global(.i-page-content) {
      gap: 12px;
    }
  }
}
.pageContentHeader {
  margin-bottom: 4px;
}

// Responsive
@include responsive(sm_nl) {
  .pageContentHeader {
    padding-top: 8px;
    > div:nth-child(1) {
      display: none;
    }
  }
}
