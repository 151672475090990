@import 'src/assets/scss/colors';

.wrapper {
  margin-top: -28px;
  padding: 28px 0;
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $black-0;
    margin: 0 0 12px 0;
  }
}
.files {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.fileDrawer {
  &:global(.mantine-Drawer-drawer) {
    height: 750px;
  }
}
