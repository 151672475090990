.wrapper {
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  &.hasComplete {
    justify-content: flex-start;
    gap: 16px;
    .viewAllBtn {
      margin-left: auto;
    }
  }
}
