@import 'src/assets/scss/colors';

.wrapper {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    li {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $gray-6;
        &:nth-child(2) {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $black-1;
        }
      }
    }
  }
}
