@import 'src/assets/scss/colors';

.wrapper {
  display: flex;
  align-items: center;
  padding: 16px 24px;
}
.breadcrumb {
  flex: 1;
}
.right {
  margin-left: auto;
  gap: 24px;
  display: flex;
  align-items: center;
}
.mobileMenuTop {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
  border-bottom: 1px solid #eaeaea;
  margin: 0 16px 16px 16px;
  .actionIcon {
    svg {
      transform: rotateY(180deg);
    }
  }
  .logo {
    margin-left: auto;
  }
}
.mobileMenuMiddle {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  height: calc(100% - 77px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 16px;
  :global {
    .mantine-Avatar-root {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
    .mantine-Menu-dropdown {
      left: 16px !important;
      right: 16px !important;
      width: calc(100% - 32px) !important;
      text-align: center;
      > div {
        > button {
          text-align: center;
        }
      }
    }
  }
}
.nav {
  padding-left: 0;
  padding-right: 0;
}
.profileMobileInfo {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $black-1;
  justify-content: space-between;
  flex: 1;
  > span {
    position: relative;
    top: 2px;
  }
  > svg {
    transform: rotate(180deg);
  }
}
.profileMenuMobile {
  :global {
    .mantine-UnstyledButton-root {
      width: 100%;
      .mantine-Button-inner {
        width: 100%;
        .mantine-Button-label {
          width: 100%;
          gap: 12px;
        }
      }
    }
  }
  .profileAvatarMobile {
    &:after {
      bottom: 0px;
      right: -2px;
      width: 8px;
      height: 8px;
      border: 1px solid $white-0;
    }
  }
  > button[aria-expanded='true'] {
    .profileMobileInfo {
      > svg {
        transform: rotate(0deg);
      }
    }
  }
}

// Print
@media print {
  .wrapper {
    display: none !important;
  }
}
