@import 'src/assets/scss/colors';

.fields {
  display: flex;
  justify-content: space-between;
  > div {
    flex: 1;
    min-width: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
  .fields {
    > div {
      flex: 1;
      min-width: calc(50% - 12px);
      max-width: calc(50% - 12px);
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  > div:not(.fields):not(.userInfo) {
    margin: 8px 0;
  }
}
.userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $black-0;
      &:first-child {
        font-size: 12px;
        color: $gray-2;
      }
    }
  }
}
