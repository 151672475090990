.uploadBtn {
  button {
    width: 100%;
    height: 53px;
  }
}
.uploadResultRow {
  > div {
    &:nth-child(2) {
      gap: 16px !important;
    }
  }
}
.modalWrapper {
  :global {
    .mantine-Modal-inner {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}
