@import 'src/assets/scss/colors';

input::placeholder {
  color: $gray-1;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
* {
  outline: none !important;
}
