.phoneWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    gap: 24px;
    > span {
      &:nth-child(1) {
        min-width: 52px;
      }
    }
  }
}
.header {
  display: flex;
  color: #0e1219;
  font-size: 16px;
  font-weight: 700;
  gap: 10px;
  padding: 0 24px;
}
