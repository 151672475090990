:global {
  .i-icon {
    // cursor: pointer;
    display: inline-flex;
  }
}
.iconArrowRight {
  transform: rotate(-90deg);
}
.iconArrowLeft {
  transform: rotate(90deg);
}
.iconArrowDown {
  transform: rotate(180deg);
}
.iconArrowTop {
  transform: rotate(-180deg);
}
