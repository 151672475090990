@import 'src/assets/scss/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  :global {
    .mantine-Select-input {
      height: 51px;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      padding: 12px 16px;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.statusDropdown {
  display: flex;
  align-items: center;
  padding: 8px 20px 8px 0px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  background: white;
  > span {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: inline-block;
    color: #abb0b6;
  }
  > div {
    margin-right: 20px;
    border-right: 1px solid #ced4da;
    > span {
      min-width: 200px;
      border: 0 !important;
      border-radius: 0 !important;
      padding-left: 16px;
      padding-right: 16px;
      svg {
        path {
          stroke: #0e121a;
        }
      }
      > span {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
.statusSelect {
  :global {
    .mantine-Select-icon {
      min-width: 105px;
    }
    .mantine-Select-rightSection {
      display: none;
    }
    .mantine-Select-input {
      opacity: 1;
      color: $black-1;
      cursor: default;
    }
  }
}
