@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.visible-hidden {
  visibility: hidden;
}
.hide {
  display: none !important;
}
.pointer {
  cursor: pointer;
}
.disabled {
  opacity: 0.4 !important;
}
.not-allowed {
  cursor: not-allowed !important;
}
.i-print-value {
  display: none;
}

// Print
@media print {
  .i-print-value {
    display: flex;
  }
  .i-print-no-value,
  .mantine-InputWrapper-error {
    display: none !important;
  }
  .mantine-Input-input {
    background: transparent !important;
    border: 0 !important;
    display: none !important;
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent !important;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent !important;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent !important;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent !important;
  }
  label {
    > span {
      display: none !important;
    }
  }
  .mantine-Input-rightSection,
  .mantine-Select-rightSection {
    display: none !important;
  }
}
