@import 'src/assets/scss/colors';
@import 'src/assets/scss/mixins';

:global {
  .mantine-Avatar-placeholder {
    background-color: $white-6;
    color: $black-0;
    border-radius: 50%;
    .mantine-Avatar-root {
      border-radius: 50%;
    }
  }
}
.wrapper {
  &.editable {
    position: relative;
    &:hover {
      .editOverlay {
        display: flex;
      }
    }
  }
  &.circle {
    border-radius: 50%;
    :global {
      .mantine-Avatar-placeholder {
        border-radius: 50%;
      }
    }
  }
  &.isStatus {
    position: relative;
    overflow: initial;
    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      right: 0;
      @include statusCircle($secondary-4, 12px);
    }
    &.statusinactive,
    &.isInactive {
      &:after {
        background: $danger-2;
      }
    }
  }
  &.hasSrc:not(.isStatus),
  &.isStatus.statusHasBorder {
    :global {
      .mantine-Avatar-root {
        border: 2px solid $secondary-1;
        border-radius: 50%;
      }
    }
  }
  &.weightmd {
    :global {
      .mantine-Avatar-placeholder {
        font-weight: 500;
      }
    }
  }
  &.fontSizemd {
    :global {
      .mantine-Avatar-placeholder {
        font-size: 14px;
      }
    }
  }
  &.notBorder {
    border: 0;
  }
}
.editOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50%;
  background: rgba($black-5, 0.4);
  display: none;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 4px;
  > label {
    color: $white-0;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
  }
}
.avtImg {
  width: 100%;
  height: 100%;
}
.avatarGroup {
  flex-direction: row-reverse;
  justify-content: flex-end;
  &.spacing_sm {
    :global {
      .mantine-Avatar-root {
        overflow: initial;
        margin-left: -30px;
      }
      .mantine-Avatar-placeholder {
        .mantine-Avatar-root {
          margin-left: 0;
          overflow: hidden;
          border: 0;
          min-height: 38px;
        }
      }
    }
  }
}
