@import 'src/assets/scss/responsive';

.filterBar {
  .propertyDropdown {
    width: 250px;
  }
  .typeDropdown {
    width: 170px;
  }
  .statusDropdown {
    width: 200px;
  }
}
.mainContent {
  .pageContent {
    gap: 12px;
  }
}
.tableResult {
  margin-top: 4px;
}
