@import 'src/assets/scss/colors';

.wrapper {
  :global {
    .mantine-Overlay-root {
      opacity: 0.25;
    }
    .mantine-Modal-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: $black-1;
    }
    .mantine-Modal-header {
      padding-bottom: 6px;
      border-bottom: 1px solid $neutral-3;
      margin-right: 0;
    }
    .mantine-Modal-modal {
      padding: 24px;
    }
    .mantine-Modal-inner {
      padding-top: 118px;
      padding-bottom: 118px;
    }
    .mantine-Modal-close {
      svg {
        width: 24px;
        height: 24px;
        path {
          stroke-width: 0;
        }
      }
    }
  }
  .footer {
    background: $white-0;
    box-shadow: 0px -2px 8px #0000001a;
    border-radius: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 16px 18px 16px;
    :global {
      .mantine-Input-input {
        background: $neutral-1;
        border-color: $neutral-3;
        &::placeholder {
          color: $gray-10;
        }
      }
    }
  }
  &.hasFooter {
    :global {
      .mantine-Modal-modal {
        padding-bottom: 92px;
      }
    }
  }
  &.fullHeight {
    :global {
      .mantine-Modal-modal {
        height: 100%;
      }
    }
  }
  &.scrollable {
    :global {
      .mantine-Modal-modal {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        .mantine-Modal-body {
          flex: 1;
          overflow: auto;
          padding-right: 10px;
          margin-right: -10px;
          padding-bottom: 1px;
        }
      }
    }
  }
}
.alertModal {
  :global {
    .mantine-Modal-inner {
      padding-top: 150px;
      padding-bottom: 150px;
    }
    .mantine-Overlay-root {
      position: absolute;
    }
    .mantine-Modal-modal {
      min-height: 535px;
      background: $white-0;
      border: 1px solid $white-4;
      border-radius: 8px;
      padding: 0;
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 54%;
        background: linear-gradient(
          180deg,
          #a6d0dd -67.75%,
          rgba(212, 236, 243, 0) 100%
        );
        border-radius: 8px 8px 0px 0px;
      }
    }
  }
  &:global(.mantine-Modal-root) {
    position: absolute;
  }
  .alertContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 56px 40px 40px 40px;
    gap: 16px;
    color: $black-0;
    h2,
    p {
      margin: 0;
    }
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 54px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    button {
      margin-top: 30px;
      padding-left: 40px;
      padding-right: 40px;
    }
    svg {
      margin-bottom: 8px;
    }
  }
}
