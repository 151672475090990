.wrapper {
  display: inline-flex;
  width: 18px;
  height: 18px;
  svg {
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    * {
      stroke: #5f98a9;
      stroke-width: 2.5;
    }
  }
}
