@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

.filterBar {
  // .propertyDropdown {
  //   width: 250px;
  // }
  // .locationDropdown {
  //   width: 200px;
  // }
}
.mainContent {
  .pageContent {
    gap: 12px;
  }
}
.tableResult {
  margin-top: 4px;
}
.pageHeader {
  :global {
    .mantine-InputWrapper-root {
      width: 360px;
    }
  }
}

// Responsive
@include responsive(sm_nl) {
  .mainContent {
    .pageContent {
      gap: 24px;
    }
  }
}
