@import 'src/assets/scss/responsive';

.pageHeader {
  :global {
    .mantine-InputWrapper-root {
      width: 360px;
    }
  }
}
.userDrawer {
  border-radius: 8px 0 0 8px;
  :global {
    .mantine-Drawer-drawer {
      top: 135px;
      height: calc(100vh - 135px);
    }
  }
}
.filterBar {
  .roleDropdown {
    width: 210px;
  }
}
