.addBtn {
  height: 30px;
  svg {
    width: 18px;
    height: 18px;
  }
  :global {
    .mantine-Button-leftIcon {
      margin-right: 4px;
    }
  }
}
.buttons {
  display: flex;
  gap: 16px;
}
.wrapper {
  overflow: initial;
}
