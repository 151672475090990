/**
* Color
**/

// Primary
$primary-0: #5f98a9;
$primary-1: #daedf2;
$primary-2: #bcd7de;
$primary-3: #8bb6c6;
$primary-4: #336878;
$primary-5: #eff6f8;

// Secondary
$secondary-0: #dbebcc;
$secondary-1: #7dae4c;
$secondary-2: #588130;
$secondary-3: #31580a;
$secondary-4: #497e14;

// Success
$success-0: #defdf3;
$success-1: #099768;
$success-2: #08855b;
$success-3: #066042;

// Warning
$warning-0: #fff3da;
$warning-1: #ec7600;
$warning-2: #c26100;
$warning-3: #9c4e00;
$warning-4: #dd7105;

// Danger
$danger-0: #f9e0e2;
$danger-1: #f93746;
$danger-2: #da0819;
$danger-3: #aa0411;
$danger-4: #d0021b;

// White
$white-0: #ffffff;
$white-1: #e5e7ea;
$white-2: #c5cbd1;
$white-3: #e0e0e0;
$white-4: #e9ecef;
$white-5: #eaeaea;
$white-6: #e8eaef;

// Black
$black-0: #000000;
$black-1: #0e121a;
$black-2: #0e1219;
$black-3: #333333;
$black-4: #3f3d56;
$black-5: #111111;
$black-6: #515254;

// Gray
$gray-0: #d0d2d9;
$gray-1: #cfd1d8;
$gray-2: #9ba1a6;
$gray-3: #c1c2c4;
$gray-4: #9d9ea1;
$gray-6: #4f4f4f;
$gray-7: #818181;
$gray-8: #828282;
$gray-9: #707478;
$gray-10: #868b90;
$gray-11: #afafaf;

// Neutral
$neutral-1: #f8f9fa;
$neutral-2: #e1e6ef;
$neutral-3: #ced4da;
$neutral-4: #696a72;
$neutral-5: #3e444f;
$neutral-6: #22262d;
