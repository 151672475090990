@import 'src/assets/scss/mixins';

.wrapper {
  :global {
    .mantine-Textarea-input {
      padding-top: 8px;
      padding-bottom: 8px;
      min-height: 65px;
    }
  }
  &.disabledOverlay {
    :global {
      .mantine-Textarea-disabled:disabled {
        @include disabledInputOverlay();
      }
    }
  }
}
