@import 'src/assets/scss/colors';

.wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  > span {
    display: inline-flex;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $primary-0;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
    color: $primary-0;
    align-items: center;
    > svg {
      transform: rotate(-45deg);
      width: 20px;
      height: 20px;
      cursor: pointer;
      path {
        stroke: $gray-2;
      }
    }
  }
  .checkIcon {
    display: flex;
    > svg {
      transform: none;
      width: 24px;
      height: 24px;
    }
  }
}
