@import 'src/assets/scss/responsive';

.col {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &.left {
    width: 60%;
  }
  &.right {
    width: 40%;
  }
}
.group {
  display: flex;
  gap: 24px;
  margin-bottom: 11px;
  > * {
    flex: 1;
  }
}
.tasks {
  padding: 0 16px;
}
.taskSection {
  min-height: 646px;
}

// Responsive
@include responsive(md1_nl) {
  .mainContent {
    :global {
      .i-page-header {
        > div {
          > * {
            min-width: 1px;
          }
        }
      }
    }
  }
  .contentInner {
    flex-wrap: wrap;
  }
  .col {
    &.left,
    &.right {
      width: 100%;
    }
    &.right {
      .group {
        order: 4;
      }
    }
  }
}
@include responsive(xs) {
  .group {
    flex-wrap: wrap;
    > * {
      min-width: 100%;
    }
  }
  .filter {
    > span {
      > span {
        display: none;
      }
    }
  }
}
@include responsive(380px) {
  .propertyDropdown {
    left: 12px !important;
  }
}
