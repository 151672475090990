@import 'src/assets/scss/colors';

.wrapper {
  background: $white-0;
  border: 1px solid $neutral-3;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    > li {
      display: flex;
      > span,
      > span > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #0e1219;
      }
      > span {
        &:nth-child(1) {
          min-width: 50%;
          max-width: 50%;
          display: flex;
          justify-content: space-between;
          padding-right: 16px;
          gap: 16px;
          font-weight: 500;
          > span {
            &:nth-child(1) {
              font-weight: 500;
            }
          }
        }
        &:nth-child(2) {
          min-width: 50%;
          max-width: 50%;
        }
      }
      &.item {
        &.hasButton {
          & + .arrow {
            height: 30px;
          }
        }
      }
      &.itemAlignright {
        justify-content: flex-end;
        padding-right: 35px;
        > span {
          &:nth-child(2) {
            text-align: right;
          }
        }
      }
    }
    &.box {
      background: #f8f9fa;
      border-radius: 0px 0px 8px 8px;
      padding: 14px 12px;
      margin-left: -12px;
      margin-right: -12px;
      margin-bottom: -10px;
      position: relative;
      > li {
        &:nth-child(1) {
          > span {
            font-weight: 500;
            > span {
              font-weight: 500;
            }
          }
        }
        &:not(:nth-child(1)) {
          > span {
            font-weight: 400;
            > span {
              font-weight: 400;
            }
          }
        }
      }
      .arrow {
        position: absolute;
        bottom: 14px;
        right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
      }
    }
  }
}
