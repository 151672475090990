@import 'src/assets/scss/mixins';
@import 'src/assets/scss/colors';

.result {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .resultRow {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .resultRowContent {
      display: flex;
      gap: 12px;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #336878;
      width: 100%;
      > svg {
        min-width: 26px;
      }
      > span {
        @include ellipsisLongText();
        &.uploadedAsInput {
          padding: 3px 10px;
          border: 1px solid $neutral-3;
          border-radius: 8px;
          color: $black-1;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          width: 100%;
        }
      }
      :global {
        .i-icon {
          position: relative;
          top: 1px;
          margin-left: 4px;
        }
      }
    }
    .resultRowMContent {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &.uploading {
      .removeIcon {
        cursor: not-allowed !important;
      }
    }
  }
  &.rowHasChildren {
    .resultRow {
      &:not(:last-child) {
        padding-bottom: 12px;
        border-bottom: 1px solid $neutral-3;
      }
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > label {
    @include inputLabel();
  }
  &.error {
    .uploadBtn {
      border-color: $danger-1;
    }
  }
  &.valueButtonHidden {
    gap: 0;
  }
  &.size_md {
    .uploadBtn {
      height: 36px;
    }
    .resultRowContent {
      min-height: 36px;
    }
  }
}
.uploadBtn {
  font-size: 14px;
  :global {
    .mantine-Button-inner {
      > span {
        &:first-child {
          margin-right: 4px;
        }
      }
    }
  }
}
.uploadName {
  cursor: pointer;
}
.errorMsg {
  @include errorMessage();
  margin-top: 8px;
}
.requiredMark {
  @include requiredMark();
}

@media print {
  .removeIcon {
    display: none;
  }
}
