@import 'src/assets/scss/responsive';

.filterBar {
  .propertyDropdown {
    width: 250px;
  }
}
.mainContent {
  .pageContent {
    gap: 12px;
  }
}
.tableResult {
  margin-top: 4px;
}
.pageHeader {
  :global {
    .mantine-InputWrapper-root {
      width: 360px;
    }
  }
}
.dropdownColumn {
  :global {
    .mantine-Select-root {
      width: 150px;
    }
  }
}
.cardBoxItems {
  :global {
    .mantine-Select-root {
      min-width: 155px;
    }
  }
}
