@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

.filterBar {
  .propertyDropdown {
    width: 250px;
  }
  .typeDropdown {
    width: 170px;
  }
  .statusDropdown {
    width: 200px;
  }
}
.mainContent {
  .pageContent {
    gap: 12px;
  }
  table {
    tbody {
      tr {
        td:nth-child(3) {
          color: $gray-2;
        }
      }
    }
  }
}
.tableResult {
  margin-top: 4px;
}
.pageHeader {
  :global {
    .mantine-InputWrapper-root {
      width: 360px;
    }
  }
}

// Responsive
@include responsive(sm_nl) {
  .mainContent {
    .pageContent {
      gap: 24px;
    }
  }
}
