@import 'src/assets/scss/colors';

.mainContent {
  display: flex;
  gap: 24px;
  position: relative;
  &.isResidentInfo {
    .pageContent {
      padding-top: 0;
    }
  }
}
.left {
  width: 200px;
  background: $white-0;
  border-radius: 8px;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  .menuItems {
    padding-top: 16px;
    > li {
      min-height: 1px;
      > ul {
        padding-bottom: 8px;
        > li {
          min-height: 25px;
        }
      }
    }
  }
}
.right {
  flex: 1;
}
.topInfo {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  margin-bottom: 16px;
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $black-1;
    margin: 0;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    margin: 0;
    color: #3e444f;
    display: flex;
    flex-direction: column;
    > span {
      &:nth-child(2) {
        font-weight: 400;
      }
    }
  }
}
.dropdown {
  margin-top: auto;
  padding: 4px 8px;
}
.infoIcons {
  display: flex;
  gap: 3px;
}
.notificationMenuItems {
  li {
    > span {
      gap: 8px;
      svg {
        width: 20px;
        height: 20px;
        margin-right: -8px;
      }
    }
  }
}
