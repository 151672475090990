@import 'src/assets/scss/mixins';
@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';
@import 'src/assets/scss/constants';

.wrapper {
  > span {
    position: relative;
    @include dropdownTarget();
    width: 100%;
    justify-content: space-between;
  }
  > label {
    @include inputLabel();
  }
  &.placeholderGray {
    > span {
      > span {
        color: $gray-1;
      }
    }
  }
  &.isError {
    > span {
      border-color: $danger-1;
      > span {
        color: #ffa8a8;
      }
    }
  }
  &.errorOverlay {
    .errorMsg {
      position: absolute;
      margin-top: 1px;
    }
  }
  &.radius_md {
    > span {
      border-radius: 8px;
    }
  }
  &.radius_sm {
    > span {
      border-radius: 4px;
    }
  }
  &.disabled {
    .input {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  &.isSingleSelected {
  }
}
.input {
  cursor: pointer;
}
.dropdown {
  min-width: 294px;
  padding: 4px;
  background: $white-0;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  // max-height: $dropdownMaxHeight;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: calc(#{$dropdownMaxHeight} - 84px);
    overflow: auto;
    li {
      list-style: none;
      padding: 12px 16px;
      border-radius: 4px;
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
      &:not(.isLabel):hover {
        background: $primary-1;
      }
      &:first-child {
        border-bottom: 1px solid $white-4;
        border-radius: 0;
        &:hover {
          border-radius: 4px;
        }
      }
      &.isLabel {
      }
    }
  }
  :global {
    .mantine-Input-input {
      padding-left: 16px;
      padding-right: 16px;
      &::placeholder {
        color: $black-1 !important;
      }
    }
    .mantine-Checkbox-inner,
    .mantine-Checkbox-inner input {
      cursor: pointer;
    }
    .mantine-Checkbox-label {
      display: inline-block;
    }
  }
  &.dropdownIsFileLocation {
    ul {
      li {
        &:first-child {
          border: 0;
        }
        &:not(.isLabel):hover {
          .locationItem {
            color: $primary-0;
            svg {
              path {
                stroke: $primary-0;
              }
            }
          }
        }
        .locationItem {
          font-weight: 500;
          &.isActive {
            color: $primary-0;
          }
        }
      }
    }
  }
  &.dropdownHasNewEntity {
    ul {
      max-height: calc(#{$dropdownMaxHeight} - 139px);
    }
  }
  &.dropdownHasNormalSub {
    ul {
      > li {
        &:not(.normalSub) {
          position: relative;
          &:before {
            content: ' ';
            height: 1px;
            display: block;
            position: absolute;
            top: 0;
            left: 8px;
            right: 8px;
            border-top: 1px solid #ced4da;
          }
          &:first-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  &.dropdownHasAll {
    ul {
      > li {
        &:first-child {
          border-bottom: 0;
        }
      }
    }
  }
  &.isSingleSelected {
    ul {
      li {
        padding: 8px 12px;
        &:first-child {
          border: 0;
        }
        .singleOption {
          cursor: pointer;
        }
        &.isActive {
          background: $primary-1;
        }
      }
    }
  }
}
.errorMsg {
  @include errorMessage();
}
.arrow {
  width: 36px;
  height: 26px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -10px;
  > svg {
    width: 20px;
    height: 20px;
  }
}
.optionLabel {
  display: flex;
  gap: 8px;
  align-items: center;
  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $gray-2;
    &:nth-child(2) {
      flex: 1;
      height: 1px;
      border-top: 1px solid $neutral-3;
    }
  }
}
.locationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    cursor: pointer;
  }
}
.parentLocation {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #adb2b8;
  position: relative;
  padding: 12px 16px;
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
    height: 1px;
    border-top: 1px solid $neutral-3;
  }
}
.newEntity {
  padding: 12px 16px;
  height: 55px;
  :global {
    .mantine-Button-leftIcon {
      margin-right: 4px;
    }
  }
  > button {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.newEntityForm {
  display: flex;
  align-items: center;
  gap: 6px;
  > button {
    border-radius: 2px;
    width: 20px;
    height: 20px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
      height: 16px;
      path {
        stroke: $white-0;
      }
    }
  }
  .neBtnIsLoading {
    :global {
      .mantine-Button-label {
        display: none;
      }
      .mantine-Button-leftIcon {
        margin: 0;
      }
    }
    &:before {
      border-radius: 2px;
    }
  }
  &.newFullWidth {
    :global {
      .mantine-InputWrapper-root {
        flex: 1;
      }
    }
  }
}
.requiredMark {
  @include requiredMark();
}
.normalSub {
  margin-left: 12px;
}

// Responsive
@include responsive(xs) {
  .wrapper {
    :global {
      .mantine-Input-input {
        font-size: 12px;
      }
    }
    > span {
      > span {
        font-size: 12px;
      }
    }
  }
  .dropdown {
    :global {
      .mantine-Select-item,
      .mantine-Checkbox-label {
        font-size: 12px;
      }
      .mantine-UnstyledButton-root {
        font-size: 12px;
        height: 28px;
      }
    }
  }
}
.label {
  &.hasInfoTooltip {
    display: flex;
    align-items: center;
    > * {
      &:last-child {
        margin-left: 6px;
      }
    }
  }
}
