.wrapper {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(3, 1fr);
  gap: 42px;
  :global {
    .mantine-Textarea-root .mantine-Textarea-wrapper textarea {
      min-height: 70px;
    }
  }
  > div {
    height: 100%;
  }
}
.uploadWrapper {
  position: relative;
  :global {
    .mantine-Checkbox-root {
      position: absolute;
      right: 16px;
      top: 4px;
    }
  }
}
.block {
  &.error {
    border-color: red;
  }
}

// Print
@media print {
  .wrapper {
    :global {
      .mantine-Card-cardSection {
        gap: 0 !important;
      }
    }
  }
  .uploadFiles {
    margin-top: 24px;
  }
  .checkbox {
    left: 0;
    :global {
      .mantine-Checkbox-inner {
        display: none !important;
      }
      .mantine-Checkbox-label {
        padding-left: 0 !important;
      }
    }
    &.isDisabled {
      display: none !important;
    }
  }
}
