@import 'src/assets/scss/colors';
@import 'src/assets/scss/mixins';

.wrapper {
  text-transform: none;
  @include transition(all 400ms ease);
  &.circle {
    border-radius: 20px;
    min-width: 20px;
    width: auto;
    padding: 0;
  }
  &.darkRed {
    background: $danger-2;
    color: $white-0;
  }
  &.isStatus {
    &:before {
      @include transition(all 400ms ease);
    }
    &.green {
      padding-right: 23px;
      position: relative;
      &:before {
        content: ' ';
        @include statusCircle($secondary-4);
        position: absolute;
        top: 4px;
        left: calc(100% - 18px);
      }
    }
    &.red {
      color: $danger-2;
      padding-left: 23px;
      position: relative;
      &:before {
        content: ' ';
        @include statusCircle($danger-2);
        position: absolute;
        top: 4px;
        left: 8px;
      }
    }
  }
  &.cursor {
    cursor: pointer;
  }
  &.alert {
    min-width: 15px;
    height: 15px;
    background: $secondary-2;
    padding-left: 3px;
    padding-right: 3px;
    :global {
      .mantine-Badge-inner {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: $white-0;
      }
    }
    &.size_md {
      min-width: 20px;
      width: auto;
      height: 20px;
      padding-left: 6px;
      padding-right: 6px;
      :global {
        .mantine-Badge-inner {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
.badgeWrapper {
  display: flex;
  > *:last-child {
    position: relative;
    top: -6px;
  }
}
