.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  button {
    min-height: 32px;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }
  > p {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: black;
    margin: 0;
  }
}
.checkistWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    li {
      display: flex;
      align-items: center;
      gap: 16px;
      padding-left: 4px;
    }
  }
  button {
    width: 70px;
  }
  :global {
    .mantine-InputWrapper-root {
      flex: 1;
    }
    .mantine-Checkbox-root input {
      cursor: pointer;
    }
  }
}
.modalWrapper {
  :global {
    .mantine-Paper-root.mantine-Modal-modal {
      padding: 16px 20px;
    }
    .mantine-Modal-header {
      margin-bottom: 8px;
      padding-bottom: 0;
      .mantine-Modal-title {
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: black;
      }
    }
  }
}
