.noonDropdown {
  max-width: 100px;
}

// Print
@media print {
  .noonDropdown {
    display: none !important;
  }
}
