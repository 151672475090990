@import 'src/assets/scss/responsive';
@import 'src/assets/scss/colors';

.wrapper {
  background: linear-gradient(
    180deg,
    #bcd7de 0%,
    #dae9ed 19.27%,
    #edf4f6 50%,
    #edf4f6 88.02%,
    #ffffff 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 16px;
  position: relative;
  .logo {
    margin-bottom: 42px;
  }
  &.isAuthInvite {
    .logo {
      margin-bottom: 16px;
      svg {
        width: 136px;
        height: 26px;
      }
    }
    .inner {
      > div {
        width: 385px;
      }
    }
  }
}
.inner {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 16px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 24px;
  z-index: 2;
  > div {
    max-width: 100%;
    width: 375px;
    margin: 0 auto;
  }
}
.additionalImage {
  height: 1px;
  position: absolute;
  border-top: 1px solid $black-4;
  left: 30px;
  right: 30px;
  bottom: 46px;
  z-index: 1;
  > img {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(2) {
      right: 0;
    }
  }
}

// Responsive
@include responsive(sm, xs) {
  .additionalImage {
    bottom: 1px;
    left: 0;
    right: 0;
    img {
      max-height: 130px;
    }
  }
  .wrapper {
    justify-content: flex-start;
    padding-top: 40px;
    padding-bottom: 150px;
    .logo {
      width: 240px;
    }
  }
}
@include responsive(xs) {
  .inner {
    padding-top: 40px;
    padding-bottom: 28px;
  }
}
