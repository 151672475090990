@import 'src/assets/scss/colors';

.wrapper {
  color: $black-0;
  &.large3 {
    font-size: 56px;
    line-height: 84px;
  }
  &.large2 {
    font-size: 36px;
    line-height: 54px;
  }
  &.large1 {
    font-size: 28px;
    line-height: 42px;
  }
  &.large {
    font-size: 24px;
    line-height: 36px;
  }
  &.regular {
    font-size: 22px;
    line-height: 33px;
  }
  &.small {
    font-size: 18px;
    line-height: 27px;
  }
  &.xSmall {
    font-size: 16px;
    line-height: 24px;
  }
  &.xxSmall {
    font-size: 14px;
    line-height: 21px;
  }
  &.tiny {
    font-weight: 700;
    font-size: 8px;
    line-height: 12px;
  }
}
