@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

.wrapper {
  :global {
    .mantine-Tabs-panel {
      padding: 12px 14px 0 14px;
    }
    .mantine-Tabs-tabsList {
      border-color: $white-3;
      padding: 0 12px;
      > .mantine-UnstyledButton-root {
        background: transparent !important;
        border: 0;
        padding: 0 12px 12px 12px;
        > .mantine-Tabs-tabLabel {
          position: relative;
          color: $gray-6;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          &:after {
            content: ' ';
            height: 2px;
            width: 100%;
            position: absolute;
            bottom: -11px;
            left: 0;
            right: 0;
            display: block;
            background: transparent;
            border-radius: 5px;
          }
        }
        &:hover,
        &[data-active] {
          > .mantine-Tabs-tabLabel {
            color: $black-1;
            &:after {
              background: $black-1;
            }
          }
        }
        &[data-active] {
          > .mantine-Tabs-tabLabel {
            font-weight: 500;
          }
        }
      }
    }
  }
}

// Responsive
@include responsive(sm_nl) {
  .wrapper {
    :global {
      .mantine-Tabs-tabsList {
        padding-left: 0;
        padding-right: 0;
        > .mantine-UnstyledButton-root {
          padding-left: 0;
          padding-bottom: 8px;
          .mantine-Tabs-tabLabel {
            width: 100%;
            &:after {
              bottom: -7px;
            }
          }
        }
      }
    }
  }
}
