@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

.wrapper {
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    gap: 48px;
    li {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 8px;
      > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $gray-6;
        &:nth-child(2) {
          font-weight: 500;
          font-size: 18px;
          line-height: 155%;
          color: $black-1;
        }
      }
      &:nth-child(2) {
        > span:nth-child(2) {
          color: $success-1;
        }
      }
      &:nth-child(3) {
        > span:nth-child(2) {
          color: $warning-4;
        }
      }
      &:nth-child(4) {
        > span:nth-child(2) {
          color: $danger-1;
        }
      }
    }
  }
}

// Responsive
@include responsive(xs) {
  .wrapper {
    ul {
      li {
        > span {
          font-size: 12px;
          &:nth-child(2) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
