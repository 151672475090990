.statusDropdown {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  :global {
    .mantine-Select-root {
      min-width: 200px;
      max-width: 200px;
    }
  }
}
.noonDropdown {
  max-width: 100px;
}

// Print
@media print {
  .statusDropdown {
    justify-content: flex-start;
    padding-left: 16px;
  }
  .noonDropdown {
    display: none !important;
  }
}
