@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

@mixin fillIcon($color) {
  > a {
    svg {
      path,
      rect,
      circle {
        fill: $color;
      }
    }
  }
}
@mixin strokeIcon($color) {
  > a {
    svg {
      path,
      rect,
      circle {
        stroke: $color;
      }
    }
  }
}

.wrapper {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    li {
      > a {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 4px;
        background: $white-0;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: $black-1;
        gap: 20px;
        text-decoration: none;
      }
      > span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $black-1;
        display: inline-block;
        margin-bottom: 12px;
      }
      &:not(.iconFill) {
        @include strokeIcon($primary-4);
      }
      &.iconFill {
        @include fillIcon($primary-4);
      }
      &:not(.notAvailable):hover,
      &.isActive {
        > a {
          background: $neutral-1;
        }
      }
      &.isActive {
        &:not(.iconFill) {
          @include strokeIcon($secondary-2);
        }
        &.iconFill {
          @include fillIcon($secondary-2);
        }
      }
      &.isLabel {
        padding-left: 10px;
      }
      &.notAvailable {
        opacity: 0.4;
        a {
          cursor: not-allowed;
        }
      }
    }
  }
  &.collapse {
    ul {
      li {
        > a {
          > span {
            display: none;
          }
        }
        > span {
          display: none;
        }
        > div {
          visibility: hidden;
          height: 159px;
        }
      }
    }
    :global {
      .mantine-Tooltip-tooltip {
        white-space: nowrap;
      }
    }
  }
  :global {
    .mantine-Tooltip-tooltip {
      margin-left: 17px;
      font-weight: 600;
      font-size: 16px;
      line-height: 155%;
      padding: 0 10px 0 14px;
      height: 26px;
      white-space: nowrap;
    }
    .mantine-Tooltip-arrow {
      width: 18px !important;
      height: 18px !important;
      left: -7px !important;
      top: 4px !important;
    }
  }
}

// Responsive
@include responsive(xs) {
}
