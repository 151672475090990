@import 'src/assets/scss/colors';
@import 'src/assets/scss/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  &.isFolder {
    .fileHeader {
      .fileName {
        text-decoration: none;
        cursor: default;
      }
    }
  }
}
.header {
  display: flex;
  gap: 16px;
  .buttons {
    margin-left: auto;
    display: flex;
    gap: 8px;
  }
  :global {
    .i-icon {
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.fileHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  overflow: hidden;
  .fileName {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $primary-4;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    :global {
      .mantine-InputWrapper-root {
        width: 100%;
      }
    }
    > span {
      @include ellipsisLongText();
      max-width: calc(100% - 30px);
      & + svg {
        min-width: 18px;
      }
    }
    & + div {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $gray-2;
    }
  }
}
.content {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  overflow: auto;
  padding-right: 15px;
  margin-right: -15px;
}
.fileInfoBlock {
  display: flex;
  flex-direction: column;
  gap: 4px;
  > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black-0;
  }
  > div {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $gray-2;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    :global {
      .mantine-Badge-root {
        > span {
          color: $black-1;
        }
      }
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
