@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

.wrapper {
  display: flex;
  gap: 28px;
  padding: 28px 0 0 0;
  &.vertical {
    flex-direction: column;
    gap: 24px;
  }
  &.gap_sm {
    gap: 16px;
  }
  &.paddingTop_sm {
    padding-top: 16px;
  }
  &.inputGray {
    :global {
      .mantine-Input-input {
        background: $neutral-3;
        &::placeholder {
          color: $black-2;
        }
      }
    }
  }
}
.pageContentHeader {
  display: flex;
  gap: 16px 24px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}
.pageContentTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black-1;
}
.filterBar {
  flex-wrap: wrap;
  display: flex;
  gap: 16px 24px;
}
.buttonBar {
  flex-wrap: wrap;
  display: flex;
  gap: 16px 24px;
}
.tableResult {
  display: none;
}

// Responsive
@include responsive(sm_nl) {
  .pageContentHeader {
    position: relative;
    .filterBar {
      & + button {
        margin-left: auto;
        height: 30px;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .buttonBar {
      gap: 8px;
      button {
        height: 30px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .tableResult {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    align-items: center;
  }
  .buttonBar {
    &.mobileFullWidth {
      flex: 1;
      min-width: 100%;
      justify-content: space-between;
    }
  }
}
