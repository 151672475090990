@import 'src/assets/scss/colors';

.wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}
.inner {
  border: 1px solid $neutral-3;
  border-radius: 8px;
  padding: 14px 24px;
  color: black;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex: 1;
  .right {
    min-width: 150px;
    max-width: 150px;
  }
}
.left {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    margin: 0;
  }
  > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}
.right {
  display: flex;
  flex-direction: column;
  gap: 8px;
  > p {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    margin: 0;
  }
  :global {
    .mantine-AvatarGroup-root {
      justify-content: flex-start;
    }
  }
}
