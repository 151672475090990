@import 'src/assets/scss/colors';

.wrapper {
  &.text_md {
    font-size: 14px;
    font-weight: 500;
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
  &.text_xl {
    font-size: 16px;
    font-weight: 500;
  }
  &.weight_xl {
    font-weight: 500;
  }
  &.weight_md {
    font-weight: 400;
    span {
      font-weight: 400;
    }
  }
  &.isOutline {
    border-color: $primary-0;
  }
}
