.modalWrapper {
  :global {
    .mantine-Modal-inner {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .mantine-Paper-root.mantine-Modal-modal {
      height: calc(100vh - 100px);
      padding: 24px;
    }
    .mantine-Input-wrapper.mantine-Select-wrapper {
      .mantine-Select-icon {
        min-width: 120px;
      }
    }
  }
  .pendingCerts {
    height: calc(100vh - 265px);
    overflow: auto;
    justify-content: flex-start;
    padding-right: 10px;
    margin-right: -10px;
    width: calc(100% + 10px);
    > div {
      &:nth-child(1) {
        flex: none;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
  .footer {
    margin-top: 24px;
    justify-content: flex-end;
    width: 100%;
    display: flex;
  }
  &.hasFrequency {
    .pendingCerts {
      height: calc(100vh - 310px);
    }
  }
}
.frequency {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  > div {
    display: flex;
    flex-direction: column;
    > span {
      &:nth-child(1) {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #868b90;
      }
      &:nth-child(2) {
        color: #0e121a;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
