.workflowNameTypeWrapper {
  display: flex;
  gap: 16px;
  > div {
    &:nth-child(1) {
      max-width: calc(66.6666666666% - 8px);
      min-width: calc(66.6666666666% - 8px);
    }
    &:nth-child(2) {
      max-width: calc(33.3333333333% - 8px);
      min-width: calc(33.3333333333% - 8px);
    }
  }
}
