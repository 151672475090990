@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

.mainContent {
  > form {
    flex: 1;
  }
}

// Responsive
@include responsive(sm_nl) {
  .mainContent {
    > form {
      :global {
        .i-page-content {
          height: auto !important;
        }
      }
    }
  }
}
