@import 'src/assets/scss/colors';
@import 'src/assets/scss/mixins';

.button {
  background: transparent;
  padding: 0;
  height: auto;
  border: 0;
  box-shadow: none;
}
.wrapper {
  &.isEmpty {
    > button {
      cursor: default;
      &:hover {
        background: transparent;
        :global {
          .mantine-Button-label {
            svg {
              path {
                fill: $neutral-3;
              }
            }
          }
        }
      }
    }
  }
}
.dropdown {
  &.dropdown_profile {
    &:global(.mantine-Menu-dropdown) {
      padding: 0;
      width: 150px;
      border: 1px solid $neutral-3;
      border-radius: 8px;
      > div {
        display: flex;
        flex-direction: column;
        > button {
          padding: 0 16px;
          margin: 16px 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $black-2;
          background: transparent !important;
          position: relative;
          &:after {
            content: ' ';
            display: block;
            position: absolute;
            height: 1px;
            left: 8px;
            right: 8px;
            bottom: -16px;
            border-top: 1px solid $neutral-2;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
  &.dropdown_actionDropdown {
    &:global(.mantine-Menu-dropdown) {
      min-width: 180px;
      :global {
        .mantine-Menu-item {
          @include dropdownMenu();
        }
      }
    }
  }
  &.isEmpty {
    &:global(.mantine-Menu-dropdown) {
      display: none !important;
    }
  }
}
