.addBtn {
  height: 30px;
  width: 155px;
  margin-left: 48px;
  svg {
    width: 18px;
    height: 18px;
  }
  :global {
    .mantine-Button-leftIcon {
      margin-right: 4px;
    }
  }
}
