@import 'src/assets/scss/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  padding: 16px 0 24px 0;
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: $black-1;
    margin: 0;
    padding: 0 16px;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    height: 100%;
    height: calc(100% - 43px);
    :global {
      .mantine-Tabs-root {
        overflow: auto;
        .mantine-Tabs-tabsList {
          position: sticky;
          top: 0;
          z-index: 2;
          background: white;
        }
      }
    }
  }
  :global {
    .mantine-Tabs-panel {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 0 24px;
  margin-top: auto;
}
