.wrapper {
  :global {
    .i-card-main-content {
      display: flex;
      justify-content: flex-end;
      margin-top: auto;
      flex-direction: row;
      align-items: center;
    }
  }
}
.previewBtn {
  margin-right: auto;
}
