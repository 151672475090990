@import 'src/assets/scss/colors';

.wrapper {
  background: $white-0;
  border: 1px solid $neutral-3;
  border-radius: 8px;
  padding: 16px 16px 24px 16px;
  display: flex;
  &.vertical {
    flex-direction: column;
    gap: 24px;
  }
}
