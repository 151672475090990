.modalWrapper {
  :global {
    .mantine-Modal-modal {
      padding: 16px;
    }
    .mantine-Modal-header {
      border: 0;
      margin-bottom: 16px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
