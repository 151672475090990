.wrapper {
  &.small {
    :global {
      .mantine-SegmentedControl-active {
        margin-top: 2px;
        margin-left: 2px;
      }
    }
  }
  &.isStatus {
    :global {
      .mantine-SegmentedControl-control {
        width: 86px;
      }
    }
  }
}
