@import 'src/assets/scss/colors';

.menuItems {
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;
  gap: 8px;
  border-top: 1px solid $neutral-3;
  padding: 8px 0;
  li {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $black-1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 25px;
    flex-direction: column;
    gap: 8px;
    svg {
      width: 20px;
      height: 20px;
    }
    > span {
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 5px;
    }
  }
  > li {
    > ul {
      width: 100%;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-bottom: 1px solid $neutral-3;
      > li {
        padding-left: 25px;
        &:hover,
        &.active {
          background: #f8f9fa;
          border-radius: 4px;
        }
      }
    }
  }
  &:not(.iconColor) {
    svg {
      path {
        stroke: $primary-4;
      }
      rect {
        stroke: $primary-4;
      }
    }
    .active {
      svg {
        path {
          stroke: $secondary-2;
        }
        rect {
          stroke: $secondary-2;
        }
      }
    }
  }
}
.nonCursor {
  cursor: default !important;
}
