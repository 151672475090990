@import 'src/assets/scss/colors';

.wrapper {
  > ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 30px;
    li {
      display: flex;
      flex-direction: column;
      gap: 5px;
      > span {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $gray-10;
        &:nth-child(2) {
          font-size: 16px;
          line-height: 20px;
          color: $black-1;
        }
      }
      &.effectiveDateWrapper {
        width: 134px;
        > span:nth-child(1) {
          display: flex;
          align-items: center;
          gap: 2px;
          > svg {
            width: 16px;
            height: 16px;
          }
        }
        &.responseDateWrapper {
          width: 144px;
        }
      }
    }
  }
  .effectiveDateInput {
    margin-top: -4px;
    > input {
      padding: 3px 4px;
      font-size: 16px;
      height: 26px;
      min-height: 26px;
      border-radius: 8px;
      line-height: 20px;
      font-weight: 500;
      color: $black-1;
    }
  }
  &.isWorkflow {
    > ul {
      gap: 60px;
    }
  }
}
