@import 'src/assets/scss/mixins';
@import 'src/assets/scss/responsive';
@import 'src/assets/scss/colors';

.wrapper {
  tbody {
    tr {
      td {
        padding: 12px 16px;
        border-color: $white-1;
        position: relative;
        color: $black-1;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        > div {
          @include tooLongText();
        }
        &:hover {
          .action {
            display: inline-flex;
          }
        }
      }
      &:first-child {
        td {
          border-top: 1px solid $white-1;
        }
      }
      &:hover {
        background: transparent;
      }
    }
  }
  thead {
    tr {
      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black-1;
        border-bottom: 0;
      }
    }
  }
  &.align_left {
    thead {
      tr {
        th {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
  &.hoverColor {
    tbody {
      .tr {
        &:hover {
          background: $primary-5;
        }
      }
    }
  }
}
.action {
  font-size: 12px;
  align-items: center;
  color: $gray-8;
  display: none;
  position: relative;
  .arrow {
    font-size: 24px;
    margin-left: 4px;
    color: $white-2;
    position: absolute;
    right: -16px;
    margin-top: 3px;
  }
  &.actionAlwaysShow {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    align-items: center;
  }
}
.action3 {
  display: flex;
  width: 21px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 0;
  cursor: pointer;
  &:hover {
    background: #daedf2;
  }
}
.tdInnerCenter {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.columnAligncenter {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.titleAligncenter {
  text-align: center !important;
}
.pagination {
  justify-content: center;
}
.selectedRow {
  :global {
    .mantine-Checkbox-root {
      width: 20px;
      height: 20px;
      margin: auto;
      .mantine-Checkbox-input {
        cursor: pointer;
      }
    }
  }
}
.result {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $black-1;
  display: inline-block;
}
.hideMenu {
  :global {
    .mantine-Menu-dropdown {
      display: none !important;
    }
  }
  svg {
    path {
      fill: $neutral-3;
    }
  }
  .action3 {
    cursor: default;
    &:hover {
      background: transparent;
    }
  }
}

// Responsive
@include responsive(sm_nl) {
  .result:not(.mobileShow) {
    display: none;
  }
}
