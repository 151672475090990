.modalWrapper {
  :global {
    .mantine-Modal-header {
      border: 0;
      margin-bottom: 0;
      .mantine-Modal-close {
        z-index: 2;
      }
    }
    .mantine-Paper-root.mantine-Modal-modal {
      padding: 20px 24px;
    }
  }
}
