@import 'src/assets/scss/mixins';
@import 'src/assets/scss/colors';

.wrapper {
  > label {
    @include inputLabel();
    display: inline-block;
    margin-bottom: 2px;
  }
}
.requiredMark {
  @include requiredMark();
}
.buttons {
  display: flex;
  gap: 16px;
}
.btn {
  padding-left: 8px;
  padding-right: 8px;
  :global {
    .mantine-Button-label {
      color: $gray-2;
      display: flex;
      gap: 8px;
      align-items: center;
      > svg {
        width: 20px;
        height: 20px;
        path {
          stroke: $gray-2;
        }
      }
    }
  }
  &.checked {
    border-color: $primary-0;
    :global {
      .mantine-Button-label {
        color: $primary-0;
        > svg {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
