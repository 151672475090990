@import 'src/assets/scss/colors';

.wrapper {
  &:global(.mantine-Drawer-drawer) {
    top: auto;
    padding: 24px;
    height: 690px;
    max-height: 100vh;
  }
}
