@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

.wrapper {
  margin-left: -8px;
  margin-right: -8px;
  border-bottom: 1px solid $white-4;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 14px;
  color: $black-1;
  .inner {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    h1 {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
  }
  .childrenWrapper {
    margin-left: auto;
    display: flex;
    gap: 24px;
    align-items: center;
  }
}

// Responsive
@include responsive(md1_nl) {
  .wrapper {
    .inner {
      h1 {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &:not(.mobileNotWrap) {
      .inner {
        flex-wrap: wrap;
        > * {
          min-width: 100%;
        }
      }
    }
  }
}
@include responsive(sm_nl) {
  .wrapper {
    &.mobileNotWrap {
      .inner {
        > * {
          min-width: 1px;
        }
        :global {
          .mantine-InputWrapper-root {
            width: 130px;
          }
        }
      }
    }
  }
}
