.modalWrapper {
  :global {
    .mantine-Modal-header {
      border: 0;
    }
    .mantine-Modal-inner {
      .mantine-Modal-modal {
        padding: 16px 14px 16px 16px;
      }
    }
  }
}
.fileItem {
  display: flex;
  align-items: center;
  gap: 8px;
  .fileContent {
    border-radius: 8px;
    border: 1px solid #ced4da;
    padding: 4px 10px;
    flex: 1;
    color: #0e121a;
    font-size: 18px;
    > span {
      &.hasFile {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.fileList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.deleteIcon {
  svg {
    path {
      stroke: #ff0000;
    }
  }
}
