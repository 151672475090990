.bottom {
  justify-content: space-between !important;
  margin-top: 8px;
  > span {
    color: #828282;
    flex: 1;
  }
  :global {
    .mantine-Button-root {
      width: 125px;
    }
  }
  &.fullWidth {
    :global {
      .mantine-Button-root {
        width: 100%;
      }
    }
  }
  &.hasCancel {
    justify-content: flex-end !important;
    gap: 8px;
    .cancelBtn {
      width: auto;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
.content {
  :global {
    .mantine-Textarea-root {
      > label {
        min-width: 45px;
      }
    }
  }
  &.inModal {
    border: 0;
    padding: 0;
    margin-top: -30px;
  }
}
