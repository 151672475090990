@import 'src/assets/scss/responsive';

.pageHeader {
  :global {
    .mantine-InputWrapper-root {
      width: 360px;
    }
  }
}
.workflowActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.workflows {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.otherHeader {
  margin-top: 8px;
}
