@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';

.wrapper {
  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    li {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $gray-7;
      a {
        text-decoration: none;
        color: $primary-0;
        font-weight: 500;
      }
    }
  }
}
.split {
  display: inline-block;
  margin: 0 5px;
}

@include responsive(sm_nl) {
  .wrapper {
    > ul {
      > li {
        display: none;
        &:last-child {
          display: block;
        }
      }
    }
  }
}
