.mainContent {
  display: flex;
  grid-area: 16px;
  padding: 2px 2px 12px 2px;
}
.sidebar {
  min-width: 325px;
  max-width: 325px;
}
.rightSide {
  padding: 14px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  :global {
    .i-page-header {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.pageContent {
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
  position: relative;
  flex: 1;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.collapseBtn {
  margin-right: auto;
}
.loading {
  z-index: 100 !important;
  :global {
    .mantine-Overlay-root {
      opacity: 0.2;
    }
  }
}
