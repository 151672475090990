.wrapper {
  position: relative;
  :global {
    .mantine-DatePicker-error {
      position: absolute;
      margin-top: 1px;
    }
    .mantine-DatePicker-wrapper {
      margin-bottom: 0;
      .mantine-DatePicker-rightSection {
        right: 8px;
        left: auto;
        width: 24px;
      }
    }
    .mantine-DatePicker-dropdown {
      padding: 16px 10px;
      .mantine-DatePicker-calendarBase {
        max-width: 280px;
      }
      .mantine-DatePicker-calendarHeader {
        margin-bottom: 16px;
        > button {
          height: 44px;
          &.mantine-DatePicker-calendarHeaderLevel {
            pointer-events: none;
            font-size: 16px;
            font-weight: 600;
            color: black;
            > svg {
              display: none;
            }
          }
          &.mantine-DatePicker-calendarHeaderControl {
            > svg {
              path {
                fill: #495057;
              }
            }
          }
        }
      }
      .mantine-DatePicker-day {
        color: #495057;
        &[data-weekend] {
          color: #495057;
        }
        &[data-outside] {
          color: #ced4da !important;
          background: transparent !important;
        }
        &[data-selected] {
          color: white;
        }
      }
      .mantine-DatePicker-cell {
        border-right: 1px solid transparent;
        border-top: 2px solid transparent;
      }
    }
  }
}
