.notes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.noteInput {
  input {
    padding-right: 36px;
  }
  & + span {
    right: 6px;
  }
}
