@import 'src/assets/scss/colors';

@mixin transition($attr) {
  -webkit-transition: $attr;
  -moz-transition: $attr;
  -ms-transition: $attr;
  -o-transition: $attr;
  transition: $attr;
}
@mixin statusCircle($color, $width: 10px) {
  display: inline-block;
  width: $width;
  height: $width;
  border-radius: 50%;
  background: $color;
}
@mixin tooLongText($line: 1) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
}
@mixin ellipsisLongText() {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin hideScrollBar() {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
@mixin dropdownTarget() {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  background: $white-0;
  border: 1px solid $white-2;
  border-radius: 8px;
  gap: 10px;
  > span {
    color: $black-1;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}
@mixin requiredMark() {
  color: $danger-4;
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
}
@mixin inputLabel() {
  font-size: 14px;
  font-weight: 500;
  word-break: break-word;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
  color: $black-1;
  line-height: 22px;
}
@mixin inputErrorOverlay() {
  :global {
    .mantine-Input-wrapper {
      margin-bottom: 0;
    }
    .mantine-InputWrapper-error {
      position: absolute;
      margin-top: 1px;
    }
  }
}
@mixin errorMessage() {
  color: $danger-1;
  font-size: inherit;
  line-height: 1.55;
  word-break: break-word;
  font-size: 12px;
  line-height: 1.2;
}
@mixin disabledInputOverlay() {
  background: $neutral-3;
  opacity: 1;
  color: $black-1;
}
@mixin dropdownMenu() {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 16px;
  &[data-hovered] {
    background-color: $primary-1;
    &.is-red {
      background-color: $danger-0;
    }
  }
}
@mixin block() {
  display: flex;
  padding: 11px 24px;
  justify-content: space-between;
  background: $white-0;
  border: 1px solid $neutral-3;
  border-radius: 8px;
  align-items: center;
  .text {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: $black-1;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
