.wrapper {
  &.flex {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &.contentFullHeight {
    :global {
      .i-page-content {
        height: 100%;
      }
    }
  }
}
