@import 'src/assets/scss/colors';
@import 'src/assets/scss/mixins';

.wrapper {
  display: flex;
  > a {
    display: inline-flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $black-1;
      @include ellipsisLongText();
    }
    > svg {
      min-width: 26px;
      width: 26px;
      height: 26px;
      path {
        stroke-width: 2px;
        stroke: $black-1;
      }
    }
  }
  &.isMarginTop {
    margin-top: auto;
  }
  &.isActive {
    background: #f8f9fa;
    > a {
      > span {
        font-weight: 600;
      }
      > svg {
        path {
          stroke: $secondary-2;
        }
      }
    }
  }
}
