@import 'src/assets/scss/colors';
@import 'src/assets/scss/mixins';

.wrapper {
  @include block();
  padding-right: 12px;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  &.titlePrimary {
    .title {
      color: $primary-4;
    }
  }
  &.border_color_primary {
    border-color: $primary-0;
  }
  &.border_color_red {
    border-color: $danger-1;
  }
  &.isCompleted {
    background: #8ad87e1f;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
}
.collapse {
  margin-left: -12px;
  width: calc(100% + 12px);
  margin-top: 12px;
}
.checkbox {
  margin-right: 6px;
}
.text {
  :global {
    .mantine-Tooltip-tooltip {
    }
  }
}
