@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive.scss';

.heading {
  margin-bottom: 8px;
  h1,
  p {
    text-align: center;
  }
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 8px;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    margin: 0 0 8px 0;
    color: $gray-2;
    width: 415px;
    margin-left: -13px;
  }
}
.fields {
  > *:not(:last-child):not(.fields) {
    margin-bottom: 8px;
  }
  > .fields {
    display: flex;
    justify-content: space-between;
    > * {
      min-width: calc(50% - 2px);
      max-width: calc(50% - 2px);
    }
  }
}
.buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 100%;
  }
  > span {
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: center;
  }
}

// Responsive
@include responsive(xs) {
  .heading {
    margin-bottom: 24px;
    > h1 {
      margin-bottom: 8px;
    }
    > p {
      font-size: 14px;
    }
  }
  .fields {
    > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
