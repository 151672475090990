@import 'src/assets/scss/colors';

.action {
  > div {
    max-width: 70%;
  }
}
.result {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.disableAssignProperty {
  border: 1px solid $neutral-3;
  border-radius: 8px;
  display: flex;
  width: 100%;
  padding: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #b7b7b7;
}
