@import 'src/assets/scss/colors';
@import 'src/assets/scss/responsive';
@import 'src/assets/scss/mixins';

:global {
  .mantine-Select-dropdown {
    margin-top: -8px;
    background: $white-0;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    .mantine-Select-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      padding: 8px 16px;
      border-radius: 4px;
      color: $black-1;
      word-break: break-word;
      &[data-hovered],
      &[data-selected] {
        background: $primary-1;
        color: $primary-0;
      }
      &[data-disabled] {
        color: #909296;
        cursor: not-allowed;
      }
    }
    .mantine-ScrollArea-viewport {
      > div {
        display: flex !important;
      }
    }
  }
  .mantine-Select-rightSection {
    > svg {
      > path {
        stroke: $neutral-3;
      }
    }
  }
}
.arrow {
  path {
    stroke: $black-2;
  }
}
.wrapper {
  :global {
    .mantine-Select-input {
      padding-right: 28px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &.errorOverlay {
    @include inputErrorOverlay();
  }
  &.disabledOverlay {
    :global {
      .mantine-Input-disabled:disabled {
        @include disabledInputOverlay();
      }
    }
  }
  &.info {
    :global {
      .mantine-Select-wrapper {
        display: flex;
        border: 1px solid #d0d2d9;
        background-color: #fff;
        border-radius: 8px;
        .mantine-Select-icon {
          position: relative;
          order: 3;
          padding: 0 20px;
          width: auto;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #abb0b6;
          &:before {
            content: ' ';
            display: block;
            position: absolute;
            width: 1px;
            top: 8px;
            bottom: 8px;
            left: 0;
            border-left: 1px solid $neutral-3;
          }
        }
        .mantine-Select-rightSection {
          position: static;
          margin-left: -23px;
          padding-right: 12px;
          > svg {
            > path {
              stroke: $black-1;
            }
          }
        }
        .mantine-Select-input {
          border: 0;
          border-radius: 8px;
        }
      }
    }
  }
}

// Responsive
@include responsive(xs) {
  :global {
    .mantine-Select-dropdown {
      .mantine-Select-item {
        font-size: 12px;
        padding: 8px 12px;
      }
    }
  }
  .wrapper {
    :global {
      .mantine-Input-input {
        font-size: 12px;
      }
    }
  }
}
