@import 'src/assets/scss/colors';

.confirmRed {
  :global {
    .mantine-Modal-modal {
      padding: 16px;
      max-width: 370px;
    }
    .mantine-Modal-header {
      margin-bottom: 12px;
      .mantine-Modal-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }
    .mantine-Modal-body {
      > div:nth-child(1) {
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .mantine-Group-root {
      gap: 16px;
      > button {
        font-size: 16px;
        font-weight: 500;
        &:nth-child(2) {
          flex: 1;
          background: #db3b45;
          color: $white-0;
        }
      }
    }
  }
  &.confirmPrimary {
    :global {
      .mantine-Group-root {
        > button {
          &:nth-child(2) {
            background: $primary-0;
          }
        }
      }
    }
  }
}
