@import 'src/assets/scss/colors';

.childrenWrapper {
  display: flex;
}
.dropdown {
  color: $black-2;
  padding: 16px;
  font-size: 14px;
  line-height: 21px;
  border-radius: 8px;
  b {
    font-weight: 600;
  }
  &.customEvent {
    padding: 4px;
    border: 1px solid $neutral-3;
    box-shadow: 0px 6px 12px rgba($black-0, 0.08);
    border-radius: 4px;
    > div {
      padding: 8px 16px;
      cursor: pointer;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $black-1;
      &:hover {
        color: $primary-4;
        background: $primary-1;
      }
    }
  }
}
.filePopoverDropdown {
  &.empty {
    color: red;
  }
}
