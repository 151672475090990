@import 'src/assets/scss/responsive';

.wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

// Responsive
@include responsive(sm_nl) {
  .wrapper {
    margin-top: 64px;
  }
}
