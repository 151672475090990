@import 'src/assets/scss/colors';

.wrapper {
  display: flex;
  align-items: center;
  background: $white-0;
  border: 1px solid $neutral-3;
  border-radius: 8px;
  padding: 6px 24px 6px 16px;
  gap: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  > svg {
    width: 26px;
    height: 26px;
  }
  &.isFolder {
    padding-right: 14px;
    .title {
      color: $black-1;
    }
    &:after {
      content: ' ';
      display: block;
      width: 1px;
      border-left: 1px solid $neutral-3;
      position: absolute;
      left: 25px;
      top: 48px;
      bottom: 0;
    }
  }
  &.isDisabled {
    cursor: default;
  }
}
.info {
  margin-left: auto;
  display: flex;
  gap: 24px;
  align-items: center;
  color: $black-1;
  > span {
    &:nth-child(1) {
      min-width: 80px;
      max-width: 80px;
    }
    &:nth-child(2) {
      min-width: 90px;
      max-width: 90px;
    }
    &:nth-child(3) {
      text-align: right;
      min-width: 100px;
      max-width: 100px;
    }
  }
}
.fileSize {
  color: $gray-2;
  font-weight: 400;
  margin-left: 20px;
}
.title {
  color: $primary-4;
}
.dropdown {
  margin-top: -28px;
}
.fileBlockWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}
.arrowIcon {
  margin-left: auto;
  display: flex;
}
.collapse {
  padding-left: 54px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
