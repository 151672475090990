.mainContent {
  flex-direction: column;
  gap: 25px;
  max-width: 1105px;
  margin: 0 auto;
  .group {
    width: 100%;
    display: flex;
    gap: 32px;
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }
}
.footer {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
